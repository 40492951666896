<template>
  <div 
    class="wh-container" 
    id="medical-transfer"
  >
    <header class="header no-print">
      <p class="title">Repasse Médico</p>
    </header>

    <Tab
      ref="medical_transfer_tabs"
      :index="currentTabIndex"
      v-bind:keys="tabs"
      v-on:onChange="onChangeTab"
    >
      <Report       
        v-if="tabs[currentTabIndex] === 'Relatório de repasse'" 
        class="tab" 
        :hasConfiguration="hasConfiguration"
        @change-tab="changeTabEmmit"
      />

      <Extracts 
        v-if="tabs[currentTabIndex] === 'Extratos'" 
        class="tab" 
      />
      
      <Profiles 
        v-if="tabs[currentTabIndex] === 'Perfis'" 
        class="tab" 
        :hasConfiguration="hasConfiguration"
        @change-tab="changeTabEmmit"
      />

      <Rules 
        v-if="tabs[currentTabIndex] === 'Regras de repasse'"
        :hasConfiguration="hasConfiguration" 
        class="tab" 
      />

      <Configuration
        v-if="tabs[currentTabIndex] === 'Configurações'"
        class="tab"
        @createConfig="hasConfiguration = true"
      />
    </Tab>
  </div>
</template>

<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Onlending',
  components: {
    Configuration: () => import('./Configuration.vue'),
    Extracts: () => import('./Extracts.vue'),
    Profiles: () => import('./Profiles.vue'),
    Report: () => import('./Report.vue'),
    Rules: () => import('./Rules.vue'),
    Tab: () => import('@/components/Tab')
  },
  created(){
    this.getOnlendingConfiguration()
  },
  mounted() {
   
    this.handleTabIndex()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      tabs: [
        'Relatório de repasse',
        'Extratos',
        'Perfis',
        'Regras de repasse',
        'Configurações'
      ],
      currentTabIndex: 0,
      hasConfiguration: false,
    }
  },
  methods: {
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/repasse_medico/${this.getPathByIndex(index)}`
        )
      }
    },
    handleTabIndex() {
      const tabs = {
        'relatorio_de_repasse': 0,
        extratos: 1,
        perfis: 2,
        'regras_de_repasse': 3,
        configuracoes: 4
      }
      this.currentTabIndex = tabs[this.$route.path.split('/').slice(2).join('/')]
    },
    getPathByIndex(index) {
      const indexes = {
        0: 'relatorio_de_repasse',
        1: 'extratos',
        2: 'perfis',
        3: 'regras_de_repasse',
        4: 'configuracoes'
      }
      return indexes[index] || 'relatorio_de_repasse'
    },
    changeTabEmmit(data) {
      const { tab, index } = data
      this.$router.push({ params: { tab } })
      this.currentTabIndex = index
    },
    getOnlendingConfiguration() {
      const isLoading = this.$loading.show()
      api.getOnlendinClinicSettingsByClinicId(this.clinic.id)
      .then((res) => {
        if(res.data.id) this.hasConfiguration = true        
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .tab {
    min-height: 70vh;
  }

  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .wh-button {
    margin: 0 5px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }
  .whw-button {
    margin: 0 5px;
    color: var(--default-white);
  }
}
</style>